const addUpperAndLowerValues = (data, value) => {
  const result = { ...data };
  result.l = data.y - value;
  result.u = data.y + value;
  return result;
};

export const createSeriesOption = (data, settings) => {
  const {
    xColumn, yColumn, valueColumn, labelColumn,
  } = settings;

  const x = [...new Set(data.map((item) => item[xColumn]))].sort();
  const y = [...new Set(data.map((item) => item[yColumn]))].sort();
  const uniqueValues = [...new Set(data.map((item) => item[valueColumn]))];

  const min = Math.min(...uniqueValues);
  const max = Math.max(...uniqueValues);
  const additionalValue = max * 0.1;

  data = data.sort((a, b) => a.Date.localeCompare(b.Date));
  // const trainingData = data.filter((item) => item.row_type === 'training').map((item) => {
  //   const result = {
  //     name: 'Training Data',
  //     x: item.Date,
  //     y: item.y,
  //   };
  //   return addUpperAndLowerValues(result, additionalValue);
  // });

  const mappedData = {};
  data.forEach((item) => {
    const key = `${item.Date}-${item.row_type}`;
    mappedData[key] = addUpperAndLowerValues(item, additionalValue);
  });
  const trainingData = [];
  const testingData = [];
  const predictedTestingData = [];
  const predictedData = [];

  x.forEach((value) => {
    const trainingKey = `${value}-training`;
    const testingKey = `${value}-testing`;
    const predictionKey = `${value}-prediction`;
    if (typeof mappedData[trainingKey] !== 'undefined') {
      trainingData.push(mappedData[trainingKey]);
    } else if (typeof mappedData[testingKey] !== 'undefined') {
      trainingData.push(mappedData[testingKey]);
    } else {
      trainingData.push(null);
    }
    // trainingData.push(typeof mappedData[trainingKey] !== 'undefined' ? mappedData[trainingKey] : null);
    // testingData.push(typeof mappedData[testingKey] !== 'undefined' ? mappedData[testingKey] : null);

    predictedTestingData.push(typeof mappedData[testingKey] !== 'undefined' ? mappedData[testingKey] : null);
    predictedData.push(typeof mappedData[predictionKey] !== 'undefined' ? mappedData[predictionKey] : null);
  });

  // const testingData = data.filter((item) => item.row_type === 'testing').map((item) => {
  //   const result = {
  //     name: 'Test',
  //     x: item.Date,
  //     y: item.y,
  //   };
  //   return addUpperAndLowerValues(result, additionalValue);
  // });

  // const predictedTestingData = data.filter((item) => item.row_type === 'testing').map((item) => {
  //   const result = {
  //     name: 'Predicted',
  //     x: item.Date,
  //     y: item.y_pred,
  //   };

  //   return addUpperAndLowerValues(result, additionalValue);
  // });

  // if (testingData.length) {
  //   const i = { ...testingData[0] };
  //   i.name = 'Training';
  //   trainingData.push(i);
  // }

  // const predictedData = data.filter((item) => item.row_type === 'prediction').map((item) => {
  //   const result = {
  //     name: 'Predicted',
  //     x: item.Date,
  //     y: item.y_pred,
  //   };
  //   return addUpperAndLowerValues(result, additionalValue);
  // });

  // console.log('x', x);
  // console.log('y', y);
  // console.log('uniqueValues', uniqueValues);
  // console.log('min', min);
  // console.log('max', max);

  const d = [];
  data.forEach((item) => {
    const xIndex = x.indexOf(item[xColumn]);
    const yIndex = y.indexOf(item[yColumn]);
    d.push([xIndex, yIndex, item[valueColumn] || '-']);
  });

  const base = -data.reduce((min, val) => Math.floor(Math.min(min, val.l)), Infinity);

  // console.log('d', d);

  const option = {
    title: {
      text: '',
      subtext: '',
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        animation: false,
        label: {
          backgroundColor: '#ccc',
          borderColor: '#aaa',
          borderWidth: 1,
          shadowBlur: 0,
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          color: '#222',
        },
      },
      // formatter(params) {
      //   return (
      //     `${params[2].name
      //     }<br />${
      //       ((params[2].y))
      //     }`
      //   );
      // },
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '1%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: x,
      axisLabel: {
        formatter(value, idx) {
          const date = new Date(value);
          return [date.getFullYear(), date.getMonth(), date.getDate()].join('-');
        },
      },
      boundaryGap: false,
    },
    yAxis: {
      // data: y,
      axisLabel: {
        // formatter(val) {
        //   return `${(val - base) * 100}%`;
        // },
      },
      axisPointer: {
        label: {
          // formatter(params) {
          //   return `${((params.value - base) * 100).toFixed(1)}%`;
          // },
        },
      },
      splitNumber: 3,
    },
    legend: {
      data: ['Predicted', 'Observations', 'Test'],
    },
    series: [
      {
        name: 'Lower Band',
        type: 'line',
        data: predictedData.map((item) => (item ? item.l : null)),
        lineStyle: {
          opacity: 0,
        },
        stack: 'confidence-band',
        symbol: 'none',
      },
      {
        name: 'Upper Band',
        type: 'line',
        data: predictedData.map((item) => (item ? item.u - item.l : null)),
        lineStyle: {
          opacity: 0,
        },
        areaStyle: {
          color: '#9CCC65',
        },
        stack: 'confidence-band',
        symbol: 'none',
      },
      {
        name: 'Predicted',
        type: 'line',
        data: predictedData.map((item) => (item ? item.y : null)),
        itemStyle: {
          color: '#33691E',
        },
        lineStyle: {
          width: 4,
        },
        showSymbol: false,
      },
      {
        name: 'Observations',
        type: 'line',
        data: trainingData.map((item) => (item ? item.y : null)),
        itemStyle: {
          color: '#1E88E5',
        },
        lineStyle: {
          width: 4,
        },
        showSymbol: false,
      },
      // {
      //   type: 'line',
      //   data: testingData.map((item) => (item ? item.y : 0)),
      //   itemStyle: {
      //     color: 'green',
      //   },
      //   lineStyle: {
      //     width: 4,
      //   },
      //   showSymbol: false,
      //   connectNulls: false,
      // },
      {
        name: 'Test',
        type: 'line',
        data: predictedTestingData.map((item) => (item ? item.y_pred : null)),
        itemStyle: {
          color: '#FFD600',
        },
        lineStyle: {
          color: '#FFD600',
          width: 4,
          type: 'dashed',
        },
        showSymbol: false,
      },
    ],
  };

  return option;
};
